class Credentials {
  init(){
    this.requestExcludeFromReportsCbClass = 'requestExcludeFromReportsCb';
    this.resetRequestButtonClass = 'resetRequestButton';

    $('body').on('click', '.' + this.resetRequestButtonClass, credentials.resetRequestButtonOnClick);
    $('body').on('change', '.' + this.requestExcludeFromReportsCbClass, this.onRequestExcludeFromReportsCbChange);
  };

  onRequestExcludeFromReportsCbChange() {
    let cr_id = $(this).data('credential-request-id');
    let exclude_checked = $(this).prop('checked');
    $.ajax({
      url: '/media/exclude_credential_request_from_reports',
      data: {credential_request_id: cr_id, exclude_from_reports: exclude_checked},
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data) {
        if (data['success']) {
          toastr.success(exclude_checked ? 'Excluded.' : 'Included.');
        } else {
          toastr.error(exclude_checked ? 'Unable To Exclude.' : 'Unable To Include.');
        };
      },
      error: function (data) {
        toastr.error(exclude_checked ? 'Unable To Exclude.' : 'Unable To Include.');
      }
    });
  };

  resetRequestButtonOnClick() {
    if (confirm('You Are Setting This Request Back To Pending. Are You Sure?')) {
      $.ajax({
        url: '/media/reset_credential_request',
        data: { credential_request_id: $(this).data('credential-request-id') },
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']){
            toastr.success('Successfully Reset Back To Pending');
            setTimeout(function () {
              location.reload();
            }, 500);
          } else {
            toastr.error('Unable To Reset Credential Request.');
          };
        },
        error: function(data){
          toastr.error('Unable To Reset Credential Request.');
        }
      });
    }
  };  

}
export let credentials = new Credentials();