import consumer from '../channels/consumer'
class HubPushQueueModal {

  init() {
    this.hubPushQueueModal = $('#hub_push_queue_modal');
    this.hubPushQueueModalQueueSize = $('#hub_push_queue_modal_queue_size');
    this.hubPushQueueModalClose = $('#hub_push_queue_modal_close');
    this.hubPushQueueModalPush = $('#hub_push_queue_modal_push');
    this.hubPushQueueModalRowDelete = $('.hubPushQueueModalRowDelete');
    this.hubPushQueueModalRowCheckbox = $('.hubPushQueueModalRowCheckbox');
    
    $('#hub_push_queue_modal_btn').on('click', hub_push_queue_modal.hubPushQueueButtonOnClick);
    this.hubPushQueueModalClose.on('click', hub_push_queue_modal.hubPushQueueCloseOnClick);
    this.hubPushQueueModalRowDelete.on('click', hub_push_queue_modal.hubPushQueueRowDeleteOnClick);
    this.hubPushQueueModalPush.on('click', hub_push_queue_modal.hubPushQueuePushOnClick);
    this.hubPushQueueModalRowCheckbox.on('change', hub_push_queue_modal.hubPushQueueRowCheckboxOnChange);

    this.subscribeToUfcAccessAdminPortalChannel();
  };

  hubPushQueueButtonOnClick() {
    hub_push_queue_modal.hubPushQueueModal.removeClass('modal__hidden');
    hub_push_queue_modal.hubPushQueueModal.addClass('modal__visible');
  };

  hubPushQueueCloseOnClick() {
    location.reload();
  };

  hubPushQueueRowDeleteOnClick() {
    if (!confirm('Are you sure?')) { return; }
    var hpq_id = $(this).data('id');
    $.ajax({
      type: 'DELETE',
      url: '/hub_push_queue/' + hpq_id + '/ajax_delete_hpq_item',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          if ($('#hpq_cb_' + hpq_id).prop('checked')) {
            $('#hpq_cb_' + hpq_id).prop('checked', false).change();
          }
          $('#hpq_row_' + hpq_id).remove();
          toastr.success('Row Deleted.');
        } else {
          toastr.error('Row Could Not Be Deleted. Please Try Again.');
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('Row Could Not Be Deleted. Please Try Again.');
      }
    });
  };

  hubPushQueueRowCheckboxOnChange() {
    if ($(this).prop('checked')) {
      hub_push_queue_modal.increaseQueueSizeCounter();
    } else {
      hub_push_queue_modal.decreaseQueueSizeCounter();
    }
  };

  hubPushQueuePushOnClick() {
    var $this = $(this);
    
    // Collect hpq ids
    let hpq_ids = new Array();
    hub_push_queue_modal.hubPushQueueModalRowCheckbox.each(function(){
      if ($(this).prop('checked')) {
        hpq_ids.push($(this).data('id'));
        $('#hpq_row_' + $(this).data('id')).removeClass();
        $('#hpq_status_' + $(this).data('id')).text('pending');
      }
    });

    if (hpq_ids.length > 0) {
      $this.text('Pushing...');
      $.ajax({
        type: 'POST',
        url: '/hub_push_queue/ajax_push_queue',
        data: {hpq_ids: hpq_ids},
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function (data, textStatus, jqXHR) {
          if (!data['success']) {
            toastr.error(data['message']);
            $this.text('Push');
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          toastr.error('Could Not Start Queue Push Process.');
          $this.text('Push');
        }
      });
    } else {
      toastr.info('Please Select At Least One Item To Push.');
    }
  };

  increaseQueueSizeCounter() {
    hub_push_queue_modal.hubPushQueueModalQueueSize.text(parseInt(hub_push_queue_modal.hubPushQueueModalQueueSize.text()) + 1);
  };

  decreaseQueueSizeCounter() {
    let queue_size = parseInt(hub_push_queue_modal.hubPushQueueModalQueueSize.text());
    if (queue_size > 0) {
      hub_push_queue_modal.hubPushQueueModalQueueSize.text(parseInt(hub_push_queue_modal.hubPushQueueModalQueueSize.text()) - 1)
    }
  };

  subscribeToUfcAccessAdminPortalChannel() {
    consumer.subscriptions.create('UfcAccessAdminPortalChannel', {
      connected() {},
      disconnected() {},
      received(data) {
        switch(data['type']) {
          case 'Hub-Push-Queue-Update':
            $.each(data['hpq_update'], function( index, value ) {
              console.log(value);
              if (value['status'] == 'success') {
                $('#hpq_row_' + value['id']).removeClass().addClass('hubPushQueueModalRowSuccess');
                $('#hpq_status_' + value['id']).text('success');
                $('#hpq_cb_' + value['id']).css('display', 'none').prop('checked', false).change();
                $('#hpq_row_delete_' + value['id']).css('display', 'none');
              } else {
                $('#hpq_row_' + value['id']).removeClass().addClass('hubPushQueueModalRowError');
                $('#hpq_status_' + value['id']).text('error');
              }
            });
            break;
          case 'Hub-Push-Queue-Done':
            $('#hub_push_queue_modal_push').text('Push');
            break;
        }
      }
    });
  };

}

export let hub_push_queue_modal = new HubPushQueueModal();
