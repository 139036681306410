class MediaUserEditModal {
  init(){
    this.media_user_edit_id = null;

    this.mediaUserEditModal = $('#media_user_edit_modal');
    this.mediaUserEditModalClose = $('#media_user_edit_modal_close');
    this.mediaUserEditModalName = $('#media_user_edit_modal_name');
    this.mediaUserEditModalBody = $('#media_user_edit_modal_body');
    this.mediaUserEditModalSubmitId = '#media_user_edit_modal_submit';

    $('body').on('click', '.mediaUserEdit', media_user_edit_modal.mediaUserEditOnClick);
    $('body').on('submit', '#media_user_edit_modal_form', media_user_edit_modal.mediaUserEditModalFormOnSubmit);
    this.mediaUserEditModalClose.on('click', media_user_edit_modal.mediaUserEditModalCloseOnClick);
  };

  mediaUserEditOnClick() {
    toastr.info('Loading User...');
    media_user_edit_modal.media_user_edit_id = $(this).data('media-user-id');
    media_user_edit_modal.mediaUserEditModalName.text($(this).data('media-user-name').name);
    media_user_edit_modal.mediaUserEditModalBody.load('/media_users/' + $(this).data('media-user-id') + '/ajax_load_media_user_edit_modal_body', {}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable To Load User.');
      } else {
        media_user_edit_modal.mediaUserEditModal.removeClass('modal__hidden');
        media_user_edit_modal.mediaUserEditModal.addClass('modal__visible');
      }
    });
  };

  mediaUserEditModalCloseOnClick() {
    media_user_edit_modal.mediaUserEditModal.removeClass('modal__visible');
    media_user_edit_modal.mediaUserEditModal.addClass('modal__hidden');
  };

  mediaUserEditModalFormOnSubmit(e) {
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: '/media_users/' + media_user_edit_modal.media_user_edit_id + '/ajax_update_media_user',
      data: $(this).serialize(),
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('User Updated.');
          setTimeout(function(){ location.reload(); }, 500);
        } else {
          toastr.error(data['message']);
          $(media_user_edit_modal.mediaUserEditModalSubmitId).prop('disabled', false);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('User Could Not Be Updated. Please Try Again.');
        $(media_user_edit_modal.mediaUserEditModalSubmitId).prop('disabled', false);
      }
    });
  };

}
export let media_user_edit_modal = new MediaUserEditModal();