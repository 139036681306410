class PersonDelete {
  init() {
    $('body').on('click', '.personDeleteButton', person_delete.personDeleteButtonOnClick);
  };

  personDeleteButtonOnClick() {
    if (confirm('Are You Sure?')) {
      let person_id = $(this).data('person-id');
      
      $.ajax({
        type: 'DELETE',
        url: `/people/${person_id}/ajax_delete_person`,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function (data, textStatus, jqXHR) {
          if (data['success']) {
            $(`#person_${person_id}`).remove();
            toastr.success('Person Deleted.');
          } else {
            toastr.error('Person Could Not Be Deleted. Please Try Again.');
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          toastr.error('Person Could Not Be Deleted. Please Try Again.');
        }
      });
    }
  };
}

export let person_delete = new PersonDelete();