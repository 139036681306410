import { epm } from './epm';
import { encoder } from './encoder';
import { credential } from './credential';
import { event_person_edit } from './event_person_edit';
import { event_person_create } from './event_person_create';
import { person_photo_preview } from '../misc/person_photo_preview';

if ($('.events.epm').length) {
  epm.init(); 
  event_person_edit.init();
  event_person_create.init();
}

if ($('.events.index').length) {
  $('.rotatingColorSelect').on('change', function(){
    $.ajax({
      url: '/events/' + $(this).data('event-id') + '/ajax_update_credential_temp_color',
      data: { credential_temp_color: $(this).val() },
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function(data){
        if (data['success']){
          toastr.success('Rotating Color Updated');
        } else {
          toastr.error('Unable To Update Rotating Color.');
        };
      },
      error: function(data){
        toastr.error('Unable To Update Rotating Color.');
      }
    });
  });
}

if ($('.events.epm').length || $('.people.index').length) {
  encoder.init(); 
  credential.init();
  person_photo_preview.init();
}

if ($('.events.new_custom_event').length || $('.events.edit_custom_event').length) {
  $('#event_custom_credentials_logo_bg_color').on('keyup', function(){
    $('.logo-image').css('background-color', $(this).val());
  });
}