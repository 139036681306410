class MediaUserApprovalHistoryModal {
  init(){
    this.media_user_approval_history_id = null;

    this.mediaUserApprovalHistoryModal = $('#media_user_approval_history_modal');
    this.mediaUserApprovalHistoryModalClose = $('#media_user_approval_history_modal_close');
    this.mediaUserApprovalHistoryModalName = $('#media_user_approval_history_modal_name');
    this.mediaUserApprovalHistoryModalBody = $('#media_user_approval_history_modal_body');

    $('body').on('click', '.mediaUserApprovalHistoryButton', media_user_approval_history_modal.mediaUserApprovalHistoryOnClick);
    this.mediaUserApprovalHistoryModalClose.on('click', media_user_approval_history_modal.mediaUserApprovalHistoryModalCloseOnClick);
  };

  mediaUserApprovalHistoryOnClick() {
    toastr.info('Loading Approval History...');
    media_user_approval_history_modal.media_user_approval_history_id = $(this).data('media-user-id');
    media_user_approval_history_modal.mediaUserApprovalHistoryModalName.text($(this).data('media-user-name').name);
    media_user_approval_history_modal.mediaUserApprovalHistoryModalBody.load('/media_users/' + $(this).data('media-user-id') + '/ajax_load_media_user_approval_history_modal_body', {}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable To Load Approval History.');
      } else {
        media_user_approval_history_modal.mediaUserApprovalHistoryModal.removeClass('modal__hidden');
        media_user_approval_history_modal.mediaUserApprovalHistoryModal.addClass('modal__visible');
      }
    });
  };

  mediaUserApprovalHistoryModalCloseOnClick() {
    media_user_approval_history_modal.mediaUserApprovalHistoryModal.removeClass('modal__visible');
    media_user_approval_history_modal.mediaUserApprovalHistoryModal.addClass('modal__hidden');
  };

}
export let media_user_approval_history_modal = new MediaUserApprovalHistoryModal();