class Fighters {
  init(){
    this.featuredOrderInput = $('.featuredOrder');
    // this.displayFeaturedRadio = $('#displayFeaturedFighters');
    // this.displayAllRadio = $('#displayAllFighters');

    $('.featuredOrder').on("blur", function() {
      var fighterId = $(this).data('fighter-id');
      var featuredOrder = $(this).val()
      fighters.updateFighterFeaturedOrder(fighterId, featuredOrder);
    });

    // $('#displayFeaturedFighters').on("click", function() {
    //   window.location = '/media/fighters';
    // });

    // $('#displayAllFighters').on("click", function() {
    //   window.location = '/media/fighters?mode=all';
    // });
  };

  updateFighterFeaturedOrder(fighterId, featuredOrder){
    $.ajax({
      type: 'PUT',
      url: `/media/fighters/${fighterId}/ajax_update_featured_order?order=${featuredOrder}`,
      data: null,
      processData: false,
      contentType: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('Featured Rank Updated.');
        } else {
          toastr.error('Featured Rank Could Not Be Updated.');
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('Featured Rank Could Not Be Updated. Please Try Again.');
      }
    });
  };
}
export let fighters = new Fighters();