// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Cropper from "cropperjs"
import toastr from "toastr"
import "select2"

window.Cropper = Cropper
window.Cropper.setDefaults(
  {
    viewMode: 0,
    autoCrop: false,
    movable: false,
    aspectRatio: 1188/1236
  }
);

window.toastr = toastr
window.toastr.options = {
  "positionClass": "toast-bottom-right",
  "timeOut": 1000
};

Rails.start()
ActiveStorage.start()

console.log("application.js loaded");

$(() => { require("hub_queue") });
$(() => { require("misc") });
$(() => {if ($(".events").length || $(".people").length) { require("hub_events") }});
$(() => {if ($(".people").length) { require("hub_people") }});
$(() => {if ($(".media").length || $(".fighters").length || $(".organizations").length) { require("media") }});