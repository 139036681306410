class SeatingCharts {
  init(){
    this.mediaRowNumUpdateButton = $('#mediaRowNumUpdate');
    this.mediaRowEventPersonSelectField = $('.mediaRowEventPersonSelect');
    this.mediaRowFirstNameTextInputField = $('.mediaRowFirstNameTextInput');
    this.mediaRowLastNameTextInputField = $('.mediaRowLastNameTextInput');
    this.mediaRowOrganizationTextInputField = $('.mediaRowOrganizationTextInput');
    this.mediaPhotographerUpdateButton = $('#mediaPhotographerNumUpdate');
    this.mediaPhotographerEventPersonSelectField = $('.mediaPhotographerEventPersonSelect');
    this.mediaPhotographerFirstNameTextInputField = $('.mediaPhotographerFirstNameTextInput');
    this.mediaPhotographerLastNameTextInputField = $('.mediaPhotographerLastNameTextInput');
    this.mediaPhotographerOrganizationTextInputField = $('.mediaPhotographerOrganizationTextInput');

    this.mediaRowNumUpdateButton.on('click', seating_charts.mediaRowNumUpdate);
    this.mediaPhotographerUpdateButton.on('click', seating_charts.mediaPhotographerNumUpdate);

    this.mediaRowEventPersonSelectField.on('change', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_row', $(this).data('row'), $(this).data('column'), 'event_person_id', $(this).data('event-id'), $(this).val(), null, null, null); });
    this.mediaRowFirstNameTextInputField.on('blur', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_row', $(this).data('row'), $(this).data('column'), 'occupant_first_name', $(this).data('event-id'), null, $(this).val(), null, null); });
    this.mediaRowFirstNameTextInputField.on('keypress', function(e) { if (e.which == 13) { seating_charts.eventMediaSeatFieldUpdate('media_row', $(this).data('row'), $(this).data('column'), 'occupant_first_name', $(this).data('event-id'), null, $(this).val(), null, null); } });
    this.mediaRowLastNameTextInputField.on('blur', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_row', $(this).data('row'), $(this).data('column'), 'occupant_last_name', $(this).data('event-id'), null, null, $(this).val(), null); });
    this.mediaRowLastNameTextInputField.on('keypress', function(e) { if (e.which == 13) { seating_charts.eventMediaSeatFieldUpdate('media_row', $(this).data('row'), $(this).data('column'), 'occupant_last_name', $(this).data('event-id'), null, null, $(this).val(), null); } });
    this.mediaRowOrganizationTextInputField.on('blur', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_row', $(this).data('row'), $(this).data('column'), 'occupant_organization', $(this).data('event-id'), null, null, null, $(this).val()); });
    this.mediaRowOrganizationTextInputField.on('keypress', function(e) { if (e.which == 13) { seating_charts.eventMediaSeatFieldUpdate('media_row', 'occupant_organization', $(this).data('event-id'), null, null, null, $(this).val()); } });

    this.mediaPhotographerEventPersonSelectField.on('change', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_photographer', $(this).data('row'), $(this).data('column'), 'event_person_id', $(this).data('event-id'), $(this).val(), null, null, null); });
    this.mediaPhotographerFirstNameTextInputField.on('blur', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_photographer', $(this).data('row'), $(this).data('column'), 'occupant_first_name', $(this).data('event-id'), null, $(this).val(), null, null); });
    this.mediaPhotographerFirstNameTextInputField.on('keypress', function(e) { if (e.which == 13) { seating_charts.eventMediaSeatFieldUpdate('media_photographer', $(this).data('row'), $(this).data('column'), 'occupant_first_name', $(this).data('event-id'), null, $(this).val(), null, null); } });
    this.mediaPhotographerLastNameTextInputField.on('blur', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_photographer', $(this).data('row'), $(this).data('column'), 'occupant_last_name', $(this).data('event-id'), null, null, $(this).val(), null); });
    this.mediaPhotographerLastNameTextInputField.on('keypress', function(e) { if (e.which == 13) { seating_charts.eventMediaSeatFieldUpdate('media_photographer', $(this).data('row'), $(this).data('column'), 'occupant_last_name', $(this).data('event-id'), null, null, $(this).val(), null); } });
    this.mediaPhotographerOrganizationTextInputField.on('blur', function(e) { seating_charts.eventMediaSeatFieldUpdate('media_photographer', $(this).data('row'), $(this).data('column'), 'occupant_organization', $(this).data('event-id'), null, null, null, $(this).val()); });
    this.mediaPhotographerOrganizationTextInputField.on('keypress', function(e) { if (e.which == 13) { seating_charts.eventMediaSeatFieldUpdate('media_photographer', $(this).data('row'), $(this).data('column'), 'occupant_organization', $(this).data('event-id'), null, null, null, $(this).val()); } });
    
    $('.select2').select2();

    $(document).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });
  };

  eventMediaSeatFieldUpdate(seat_type, seat_row, seat_column, db_column, event_id, event_person_id, occupant_first_name, occupant_last_name, occupant_organization) {
    $.ajax({
      url: '/media/ajax_event_media_seat_update',
      data: {seat_type, seat_row, seat_column, db_column, event_id, event_person_id, occupant_first_name, occupant_last_name, occupant_organization},
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function(data){
        if (data['success']) {
          if (data['updated']) {
            toastr.success('Updated.');
          }
        } else {
          toastr.error(data['message']);
        }
      },
      error: function(data){
        toastr.error('Unable To Update.');
      }
    });
  };

  mediaRowNumUpdate(e) {
    if (!$('#mediaRowNumRows').val() || !$('#mediaRowNumColumns').val()) {
      toastr.error('Please specify both rows and columns.');
    } else {
      $.ajax({
        url: '/events/' + $(this).data('event-id') + '/ajax_update_media_row_fields',
        data: {rows: $('#mediaRowNumRows').val(), columns: $('#mediaRowNumColumns').val()},
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']) {
            toastr.success('Chart Size Updated.');
            setTimeout(function () {
              location.reload();
            }, 500);
          } else {
            toastr.error('Unable To Update Rows or Columns.');
          }
        },
        error: function(data){
          toastr.error('Unable To Update Rows or Columns.');
        }
      });
    }
  };

  mediaPhotographerNumUpdate(e) {
    if (!$('#mediaPhotographerNumRows').val() || !$('#mediaPhotographerNumColumns').val()) {
      toastr.error('Please specify both rows and columns.');
    } else {
      $.ajax({
        url: '/events/' + $(this).data('event-id') + '/ajax_update_media_photographer_fields',
        data: {rows: $('#mediaPhotographerNumRows').val(), columns: $('#mediaPhotographerNumColumns').val()},
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']) {
            toastr.success('Chart Size Updated.');
            setTimeout(function () {
              location.reload();
            }, 500);
          } else {
            toastr.error('Unable To Update Rows or Columns.');
          }
        },
        error: function(data){
          toastr.error('Unable To Update Rows or Columns.');
        }
      });
    }
  };

}
export let seating_charts = new SeatingCharts();