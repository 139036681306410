class EventPersonEdit {
  init() {
    this.cropper = null;
    this.person_edit_id = null;
    this.event_person_edit_id = null;

    this.eventPersonEdit = $('#person_edit_modal');
    this.eventPersonEditClose = $('#person_edit_modal_close');
    this.eventPersonEditPersonName = $('#person_edit_modal_person_name');
    this.eventPersonEditFormHolder = $('#person_edit_modal_form_holder');
    $('body').on('click', '.personEditButton', event_person_edit.personEditButtonOnClick);
    this.eventPersonEditClose.on('click', event_person_edit.personEditCloseOnClick);
    $('body').on('submit', '#person_form', event_person_edit.personFormOnSubmit);

    // Cred pic
    this.eventPersonEditCredPicId = '#person_edit_modal_cred_pic';
    this.eventPersonEditCredPicEditId = '#person_edit_modal_cred_pic_edit';
    this.eventPersonEditCredPicEditButtonHolderClass = '.credPicEditButtonHolder';
    this.eventPersonEditCredPicEditButtonsHolderClass = '.credPicEditButtonsHolder';
    this.eventPersonEditCredPicCropperClass = '.eventPersonEditCredPicCropper';
    this.eventPersonEditCredPicSaveId = '#cred_pic_save';
    this.eventPersonEditCredPicCancelId = '#cred_pic_cancel';
    this.eventPersonEditCredPicSelectorHolderId = '#cred_pic_file_selector_holder';
    this.eventPersonEditCredPicSelectorId = '#cred_pic_file_selector';

    $('body').on('click', event_person_edit.eventPersonEditCredPicEditId, event_person_edit.personEditCredPicEditClick);
    $('body').on('click', event_person_edit.eventPersonEditCredPicCropperClass, event_person_edit.personEditCredPicCropperClick);
    $('body').on('click', event_person_edit.eventPersonEditCredPicSaveId, event_person_edit.personEditCredPicSaveClick);
    $('body').on('click', event_person_edit.eventPersonEditCredPicCancelId, event_person_edit.personEditCredPicCancelClick);
    $('body').on('change', event_person_edit.eventPersonEditCredPicSelectorId, event_person_edit.eventPersonEditCredPicSelectorChange);
  };

  personEditButtonOnClick() {
    let departmentsList = $("#epm_table").data('event-departments');
    let rolesList = $("#epm_table").data('event-roles');
    let credTypes = $("#epm_table").data('event-cred-types');
    let name = $(this).data('person-name').name;
    let credentialTypeRefId = $(this).data('event-person-credential-type-ref-id') ? parseInt($(this).data('event-person-credential-type-ref-id')) : null;
    let credential2TypeRefId = $(this).data('event-person-credential2-type-ref-id') ? parseInt($(this).data('event-person-credential2-type-ref-id')) : null;
    event_person_edit.populateEventPersonEdit($(this).data('event-person'), $(this).data('person'), credentialTypeRefId, credential2TypeRefId, name, departmentsList, rolesList, credTypes);
    event_person_edit.eventPersonEdit.removeClass('modal__hidden');
    event_person_edit.eventPersonEdit.addClass('modal__visible');
  };

  personEditCloseOnClick() {
    event_person_edit.eventPersonEdit.removeClass('modal__visible');
    event_person_edit.eventPersonEdit.addClass('modal__hidden');
  };

  populateEventPersonEdit(eventPersonJson, personJson, credentialTypeRefId, credential2TypeRefId, name, departments, roles, credTypes) {
    let modal_form = '';
    event_person_edit.eventPersonEditPersonName.html(name);

    event_person_edit.event_person_edit_id = eventPersonJson.id;
    event_person_edit.person_edit_id = eventPersonJson.person_id;

    // Form
    modal_form += '<form id="person_form">';
    
    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Photo</h5>';
    modal_form += `
      <div id="person_edit_modal_cred_pic_holder">
          <img id="person_edit_modal_cred_pic" width="250" style="margin: auto;">
      </div>
      <div id="person_edit_modal_cred_pic_buttons_holder">
          <div id="cred_pic_file_selector_holder" class="file-input">
              <input type="file" id="cred_pic_file_selector" accept="image/png, image/jpeg">
              <label id="cred_pic_file_selector_label" for="cred_pic_file_selector">Upload New Credential Photo</label>
          </div>
          <div>
              <div class="credPicEditButtonHolder">
                  <button type="button" id="person_edit_modal_cred_pic_edit">Edit Current Photo</button>
              </div>
              <div class="credPicEditButtonsHolder visuallyHidden">
                  <div><button type="button" id="cred_pic_crop" class="eventPersonEditCredPicCropper">Crop</button></div>
                  <div><button type="button" id="cred_pic_rotate" class="eventPersonEditCredPicCropper">Rotate</button></div>
                  <div><button type="button" id="cred_pic_save" class="disabled" style="background-color: green;">Save</button></div>
              </div>
              <div class="credPicEditButtonsHolder visuallyHidden">
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div><button type="button" id="cred_pic_cancel">Cancel</button></div>
              </div>
          </div>
      </div>
    `;
    modal_form += '</div>';

    modal_form += '<div class="sideBySideContainers--container">';

    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>First Name *</h5>';
    modal_form += '<input type="text" required="required" id="firstName" name="firstName" placeholder="First" value="' + event_person_edit.emptyStringIfNull(personJson.first_name) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Middle Name</h5>';
    modal_form += '<input type="text" id="middleName" name="middleName" placeholder="Middle" value="' + event_person_edit.emptyStringIfNull(personJson.middle_name) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Last Name *</h5>';
    modal_form += '<input type="text" required="required" id="lastName" name="lastName" placeholder="Last" value="' + event_person_edit.emptyStringIfNull(personJson.last_name) + '">';
    modal_form += '</div>';
    modal_form += '</div>';

    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<input type="text" id="firstNameOverride" name="firstNameOverride" placeholder="Override" value="' + event_person_edit.emptyStringIfNull(personJson.first_name_override) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<input type="text" id="middleNameOverride" name="middleNameOverride" placeholder="Override" value="' + event_person_edit.emptyStringIfNull(personJson.middle_name_override) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<input type="text" id="lastNameOverride" name="lastNameOverride" placeholder="Override" value="' + event_person_edit.emptyStringIfNull(personJson.last_name_override) + '">';
    modal_form += '</div>';
    modal_form += '</div>';

    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr; margin-top: -26px;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Email *</h5>';
    modal_form += '<input type="text" required="required" id="email1" name="email1" placeholder="Email" value="' + event_person_edit.emptyStringIfNull(personJson.email1) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Phone</h5>';
    modal_form += '<input type="text" id="phone1" name="phone1" placeholder="Phone" value="' + event_person_edit.emptyStringIfNull(personJson.phone1) + '">';
    modal_form += '</div>';
    modal_form += '</div>';
    modal_form += '<h5 style="margin: 0 0 1em;">Seat Number</h5>';
    modal_form += '<input type="text" id="seatNumber" name="seatNumber" placeholder="Seat Number" value="' + event_person_edit.emptyStringIfNull(eventPersonJson.seat_number) + '">';

    modal_form += '</div>';

    modal_form += '</div>';

    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Department</h5><select id="departmentSelect" name="department" class="form-control">\n' +
      "<option value></option>\n" +
      event_person_edit.generateSelectValues(departments, eventPersonJson.department_ref_id) + '\n</select>';
    modal_form += '<input type="text" id="departmentOverride" name="departmentOverride" placeholder="Department Name Override" value="' + event_person_edit.emptyStringIfNull(eventPersonJson.department_ref_name_override) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Role</h5><select id="roleSelect" name="role" class="form-control">\n' +
      "<option value></option>\n" +
      event_person_edit.generateSelectValues(roles, eventPersonJson.role_ref_id) + '\n</select>';
    modal_form += '<input type="text" id="roleOverride" name="roleOverride" placeholder="Role Name Override" value="' + event_person_edit.emptyStringIfNull(eventPersonJson.role_ref_name_override) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<h5>Credential Types</h5><select id="accessTypeSelect" name="accessType" class="form-control">\n' +
      "<option value></option>\n" +
      event_person_edit.generateSelectValues(credTypes, credentialTypeRefId) + '\n</select>';
    modal_form += '<select id="accessType2Select" name="accessType2" class="form-control">\n' +
      "<option value></option>\n" +
      event_person_edit.generateSelectValues(credTypes, credential2TypeRefId) + '\n</select>';
    modal_form += '</div>';
    modal_form += '</div>';


    modal_form += '<div class="buttons"><input id="person_form_submit" class="button" type="submit" name="commit" value="Update" /></div>';
    modal_form += '</form>';

    event_person_edit.eventPersonEditFormHolder.html(modal_form);

    // Cred Pic
    $(event_person_edit.eventPersonEditCredPicSelectorId).val(null);
    event_person_edit.destroyCropper();
    event_person_edit.showEditCredPicButton();
    $(event_person_edit.eventPersonEditCredPicId).attr('src', '/people/' + event_person_edit.person_edit_id + '/cred_pic?rand=' + Math.random());
  };

  getValueString(iter, data) {
    if (iter === data) {
      return `value=${iter} selected`;
    } else {
      return `value=${iter}`;
    }
  };

  generateSelectValues(data, personValue) {
    let output = '';
    for (const element of data) {
      output += `<option  ${this.getValueString(element.id, personValue)}>${element.name}</option>\n`;
    }
    return output;
  };

  personFormOnSubmit(e) {
    e.preventDefault();
    let data = {
      id: event_person_edit.event_person_edit_id,
      first_name: $('#firstName').val(),
      first_name_override: $('#firstNameOverride').val(),
      middle_name: $('#middleName').val(),
      middle_name_override: $('#middleNameOverride').val(),
      last_name: $('#lastName').val(),
      last_name_override: $('#lastNameOverride').val(),
      email1: $('#email1').val(),
      phone1: $('#phone1').val(),
      department_ref_id: $('#departmentSelect').val(),
      department_ref_name_override: (($('#departmentOverride').val() != '') ? $('#departmentOverride').val() : null),
      role_ref_id: $('#roleSelect').val(),
      role_ref_name_override: (($('#roleOverride').val() != '') ? $('#roleOverride').val() : null),
      credential_type_ref_id: $('#accessTypeSelect').val(),
      credential2_type_ref_id: $('#accessType2Select').val(),
      seat_number: $('#seatNumber').val()
    }
    $.ajax({
      type: 'POST',
      url: '/event_people/' + data.id + '/ajax_update_event_person',
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('EPM Person Updated.');
          setTimeout(function () {
            location.reload();
          }, 500);
        } else {
          toastr.error(data['message']);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('EPM Person Could Not Be Updated. Please Try Again.');
      }
    });
  };

  eventPersonEditCredPicSelectorChange() {
    if (this.files && this.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        $(event_person_edit.eventPersonEditCredPicId).attr('src', e.target.result);
        event_person_edit.personEditCredPicEditClick();
        $(event_person_edit.eventPersonEditCredPicSaveId).removeClass('disabled');
      };
      reader.readAsDataURL(this.files[0]);
    }
  };

  personEditCredPicEditClick() {
    event_person_edit.destroyCropper();
    event_person_edit.cropper = new Cropper(document.getElementById('person_edit_modal_cred_pic'));
    event_person_edit.showEditCredPicActionButtons();
  };

  personEditCredPicCropperClick() {
    if (event_person_edit.cropper == null) { toastr.error('Unable To Edit. Please Refresh And Try Again.'); return; }
    if (this.id == 'cred_pic_rotate') {
      event_person_edit.cropper.rotate(90);
      $(event_person_edit.eventPersonEditCredPicSaveId).removeClass('disabled');
    } else {
      if (Object.keys(event_person_edit.cropper.getCropBoxData()).length != 0) {
        $(event_person_edit.eventPersonEditCredPicId).attr('src', event_person_edit.cropper.getCroppedCanvas({}).toDataURL());
        event_person_edit.destroyCropper();
        event_person_edit.cropper = new Cropper(document.getElementById('person_edit_modal_cred_pic'));
        $(event_person_edit.eventPersonEditCredPicSaveId).removeClass('disabled');
      } else {
        toastr.info('Draw A Box On The Image To Crop.');
      }
    }
  };

  personEditCredPicSaveClick() {
    if ($(this).hasClass('disabled')) { return; }
    if (event_person_edit.cropper == null) { toastr.error('Unable To Save. Please Refresh And Try Again.'); return; }
    $(event_person_edit.eventPersonEditCredPicSaveId).addClass('disabled').text('Saving...');
    setTimeout(function(){
      event_person_edit.cropper.getCroppedCanvas({}).toBlob(function (blob) {
        let form_data = new FormData();
        form_data.append('cred_pic', blob);
        $.ajax('/people/' + event_person_edit.person_edit_id + '/ajax_save_cred_pic', {
          method: 'POST',
          data: form_data,
          processData: false,
          contentType: false,
          success: function () {
            event_person_edit.personEditCredPicCancelClick();
            $('#ep_' + event_person_edit.event_person_edit_id + '_photo_checkmark').html('&check;');
            $('#ep_' + event_person_edit.event_person_edit_id + '_photo_checkmark').removeClass('red').addClass('green photo-preview');
            toastr.success('Credential Photo Saved.');
          },
          error: function () {
            $(event_person_edit.eventPersonEditCredPicSaveId).removeClass('disabled').text('Save');
            toastr.error('Unable To Save Credential Photo. Please Try Again.');
          }
        });
      }, 'image/png');
    }, 100);    
  };

  personEditCredPicCancelClick() {
    $(event_person_edit.eventPersonEditCredPicId).attr('src', '/people/' + event_person_edit.person_edit_id + '/cred_pic?rand=' + Math.random());
    $(event_person_edit.eventPersonEditCredPicSelectorId).val(null);
    event_person_edit.destroyCropper();
    event_person_edit.showEditCredPicButton();
  };

  showEditCredPicButton() {
    $(event_person_edit.eventPersonEditCredPicSelectorHolderId).removeClass('visuallyHidden');
    $(event_person_edit.eventPersonEditCredPicEditButtonHolderClass).removeClass('visuallyHidden');
    $(event_person_edit.eventPersonEditCredPicEditButtonsHolderClass).addClass('visuallyHidden');
  };

  showEditCredPicActionButtons() {
    $(event_person_edit.eventPersonEditCredPicSaveId).addClass('disabled').text('Save');
    $(event_person_edit.eventPersonEditCredPicSelectorHolderId).addClass('visuallyHidden');
    $(event_person_edit.eventPersonEditCredPicEditButtonHolderClass).addClass('visuallyHidden');
    $(event_person_edit.eventPersonEditCredPicEditButtonsHolderClass).removeClass('visuallyHidden');
  };

  destroyCropper() {
    if (event_person_edit.cropper != null) {
      event_person_edit.cropper.destroy();
      event_person_edit.cropper = null;
    }
  };

  emptyStringIfNull(str) {
    if (str === null) {
      return '';
    } else {
      return str;
    }
  };

}

export let event_person_edit = new EventPersonEdit();
