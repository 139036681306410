class Epm {

    init() {
        this.eventId = $('#event_attributes').data('event-id');

        this.importButton = $("#people_import_button");
        this.importModal = $("#import_users_modal");
        this.modalClose = $('#import_users_modal_close');
        this.eventsSection = $('#eventSearchWrapper');
        this.peopleSection = $('#userSearchWrapper');
        this.peopleSearchField = $('#userSearchField');
        this.eventsSelectButton = $('#eventSelectButton');
        this.peopleSearchButton = $('#user-search-button');
        this.eventsSelect = $('#eventSelect');
        this.peopleResultsBody = $('#people-results-body');
        this.resultsTableWrapper = $('#results-table-container');
        this.importPeopleButton = $('#import-people-button');
        this.resultsLabel = $('#results-label');

        this.importButton.on('click', epm.onPeopleImportButtonClick);
        this.modalClose.on('click', epm.onPeopleImportModalClose);
        this.importPeopleButton.on('click', epm.importPeople);
        this.eventsSelectButton.on('click', epm.getPeopleFromEvent);
        this.peopleSearchButton.on('click', epm.searchPeople);
        this.peopleSearchField.on('keyup', function (e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                epm.peopleSearchButton.click();
            }
        });

        this.importPeopleButton.hide();
        this.resultsLabel.hide();
        this.searchResults = [];

        $('input[type=radio][name=importPeopleSearchType]').on('change', function () {
            epm.peopleImportModalClearResults();

            switch ($(this).val()) {
                case 'event':
                    epm.eventsSection.show();
                    epm.peopleSection.hide();
                    break;
                case 'people':
                    epm.peopleSection.show();
                    epm.eventsSection.hide();
                    break;
            }
        });

        this.printCredentialButtonClass = '.printCredential';
        $('.events.epm').on('change', '.credentialOctagonAccessCb', this.onCredentialOctagonAccessCbChange);
        $('.events.epm').on('change', '.credentialEscortCb', this.onCredentialEscortCbChange);
        $('.events.epm').on('click', '.personDeleteButton', this.onPersonDeleteButtonClick);

        this.editVenueNameModal = $('#venue_edit_modal');
        $('.events.epm').on('click', '#venue_name_edit', this.onVenueNameEditButtonClick);
        $('.events.epm').on('click', '#venue_edit_modal_close', this.onVenueNameEditModalCloseButtonClick);
        $('.events.epm').on('click', '#venue_edit_modal_update', this.onVenueNameEditModalUpdateButtonClick);
    };

    onCredentialOctagonAccessCbChange() {
        let $this = $(this);
        let event_person_id = $(this).data('event-person-id');
        let octagon_access_checked = $(this).prop('checked');
        let credential_num = $(this).data('credential-num');
        $.ajax({
            url: '/event_people/' + event_person_id + '/ajax_update_credential_octagon_access',
            data: {credential_num: credential_num, credential_octagon_access: octagon_access_checked},
            method: 'POST',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data) {
                if (data['success']) {
                    $(`#ep_crd${(credential_num ? credential_num : '')}_${event_person_id}`).data('event-person-credential-octagon-access', octagon_access_checked);
                    $(`#ep_bulk${(credential_num ? credential_num : '')}_${event_person_id}`).data('event-person-credential-octagon-access', octagon_access_checked);
                    toastr.success('Credential Octagon Access Updated.');
                } else {
                    toastr.error('Unable To Update Credential Octagon Access.');
                    $this.prop('checked', !$this.prop('checked'));
                }
                ;
            },
            error: function (data) {
                toastr.error('Unable To Update Credential Octagon Access.');
                $this.prop('checked', !$this.prop('checked'));
            }
        });
    };

    onCredentialEscortCbChange() {
        let $this = $(this);
        let credential_num = $(this).data('credential-num');
        $.ajax({
            url: '/event_people/' + $(this).data('event-person-id') + '/ajax_update_credential_escort',
            data: {credential_num: credential_num, credential_escort: $(this).prop('checked')},
            method: 'POST',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data) {
                if (data['success']) {
                    if (data['ref_changed'] == false) {
                        toastr.error('Unable To Update Credential to Escort. Are They Perm or Temp?');
                        $this.prop('checked', !$this.prop('checked'));
                    } else {
                        const new_ref = data['new_ref'];
                        $('#ep_' + $this.data('event-person-id') + '_credential_type').html(data['new_name']);
                        $('#ep_edit_' + $this.data('event-person-id')).data('event-person-credential-type-ref-id', new_ref);
                        $(`#ep_crd${(credential_num ? credential_num : '')}_` + $this.data('event-person-id')).data('event-person-credential-type-ref-id', new_ref);
                        $(`#ep_enc${(credential_num ? credential_num : '')}_` + $this.data('event-person-id')).data('event-person-credential-type-ref-id', new_ref);
                        $(`#ep_bulk${(credential_num ? credential_num : '')}_` + $this.data('event-person-id')).data('event-person-credential-type-ref-id', new_ref);
                        toastr.success(`Primary Credential Set To Escort.`);
                    }

                } else {
                    toastr.error('Unable To Update Primary Credential Escort Designation.');
                    $this.prop('checked', !$this.prop('checked'));
                }
            },
            error: function (data) {
                toastr.error('Unable To Update Primary Credential Escort Designation.');
                $this.prop('checked', !$this.prop('checked'));
            }
        });
    };

    onPersonDeleteButtonClick() {
        if (confirm('Are You Sure?')) {
            $.ajax({
                url: '/event_people/' + $(this).data('event-person-id') + '/ajax_delete_event_person',
                method: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
                },
                success: function (data) {
                    if (data['success']) {
                        toastr.success('Person Removed From EPM.');
                        setTimeout(function () {
                            location.reload();
                        }, 500);
                    } else {
                        toastr.error('Unable To Remove Person From EPM.');
                    }
                    ;
                },
                error: function (data) {
                    toastr.error('Unable To Remove Person From EPM.');
                }
            });
        }
    };

    onVenueNameEditButtonClick() {
        $('#venue_edit_modal_name_override').val($('#venue_name').text());

        epm.editVenueNameModal.removeClass('modal__hidden');
        epm.editVenueNameModal.addClass('modal__visible');
    };

    onVenueNameEditModalCloseButtonClick() {
        epm.editVenueNameModal.removeClass('modal__visible');
        epm.editVenueNameModal.addClass('modal__hidden');
    };

    onVenueNameEditModalUpdateButtonClick() {
        $.ajax({
            url: '/events/' + $(this).data('event-id') + '/ajax_update_venue_override',
            method: 'POST',
            data: {venue_name_override: $('#venue_edit_modal_name_override').val()},
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data) {
                if (data['success']) {
                    toastr.success('Venue Updated.');
                    setTimeout(function () {
                        location.reload();
                    }, 500);
                } else {
                    toastr.error('Unable To Update Venue.');
                }
                ;
            },
            error: function (data) {
                toastr.error('Unable To Update Venue.');
            }
        });
    };

    onPeopleImportButtonClick() {
        epm.peopleImportModalClearResults();
        epm.importModal.removeClass('modal__hidden');
        epm.importModal.addClass('modal__visible');
    }

    onPeopleImportModalClose() {
        epm.importModal.removeClass('modal__visible');
        epm.importModal.addClass('modal__hidden');
        epm.peopleImportModalClearResults();
    }

    peopleImportModalClearResults() {
        epm.peopleResultsBody.html('');
        epm.resultsTableWrapper.html('');
        epm.importPeopleButton.hide();
    }

    importPeople() {
        if (epm.importPeopleButton.text() === 'Importing...') {
            return;
        }
        epm.importPeopleButton.text('Importing...');
        let people = [];
        let checkedPeople = $('#people-results-body').find('input[type="checkbox"]:checked');
        if (!checkedPeople) {
            return;
        }
        checkedPeople.each(function () {
            people.push({
                id: $(this).data('person-id'),
                department_hub_id: $(this).data('department-hub-id'),
                role_hub_id: $(this).data('role-hub-id'),
                credential_type_hub_id: $(this).data('credential-type-hub-id')
            });
        })
        let json = {
            people
        }
        let url = '/events/' + epm.eventId + '/ajax_import_people';
        $.ajax({
            type: 'POST',
            url: url,
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(json),
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data, textStatus, jqXHR) {
                toastr.success('People Imported.');
                setTimeout(function () {
                    location.reload();
                }, 200);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                epm.importPeopleButton.text('Import Selected');
                toastr.error('Could not Import');
            }
        });
    }

    getPeopleFromEvent() {
        epm.peopleImportModalClearResults();
        let sourceEventId = epm.eventsSelect.val();
        if (!sourceEventId) {
            toastr.error('Please Select An Event.');
            return;
        }
        let $this = $(this);
        epm.selectedImportEvent = sourceEventId;
        $this.text('Searching...');
        $.ajax({
            type: 'GET',
            url: '/events/' + epm.eventId + '/ajax_get_people_from_event/' + sourceEventId,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data, textStatus, jqXHR) {
                $this.text('Search');
                epm.populateResultsTable(data, true);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $this.text('Search');
                toastr.error('Cannot Search At The Moment. Please Try Again.');
            }
        });

    }

    searchPeople() {
        epm.peopleImportModalClearResults();
        let filter = epm.peopleSearchField.val();
        if (!filter) {
            toastr.error('Please Enter A Search Term.');
            return;
        }
        let $this = $(this);
        $this.text('Searching...');
        $.ajax({
            type: 'GET',
            url: '/events/' + epm.eventId + '/ajax_person_search',
            data: {filter: filter},
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (data, textStatus, jqXHR) {
                $this.text('Search');
                epm.populateResultsTable(data, false);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $this.text('Search');
                toastr.error('Cannot Search At The Moment. Please Try Again.');
            }
        });

    }

    populateResultsTable(data, eventsMode) {
        epm.resultsLabel.show();

        if (!data || data.length == 0) {
            epm.populateEmptyResult(false);
            return;
        }
        epm.searchResults = data;
        if (eventsMode) {
            epm.populateTableHeaderForEventsSearch();
        } else {
            epm.populateTableHeaderForPeopleSearch();
        }
        data.forEach(person => {
            let fullName = `${person.first_name} ${person.last_name}`;
            let tableRow = '';
            if (eventsMode) {
                tableRow = `<tr class="event-person-table-row">
              <th scope="row"><input type="checkbox" class="selectedPersons" data-person-id="${person.id}" data-department-hub-id="${person.department}" data-role-hub-id="${person.role}" data-credential-type-hub-id="${person.credential_type}" name="userSelect"/></th>
              <td>${fullName}</td>
              <td><div class="checkmark ${person.credential_type_is_permanent ? person.printed_and_encoded ? 'green' : 'red' : ''}">${person.credential_type_is_permanent ? person.printed_and_encoded ? '&check;' : '&compfn;' : '&ndash;'}</div>${person.credential2_type ? `<div class="checkmark ${person.credential2_type_is_permanent ? person.printed_and_encoded2 ? 'green' : 'red' : ''}">${person.credential2_type_is_permanent ? person.printed_and_encoded2 ? '&check;' : '&compfn;' : '&ndash;'}</div>`: ''}</td>
              <td class="event-person-table-email">${person.email1 ? person.email1.split('@').join('@&#8203;') : ''}${person.email1 && person.phone1 ? '<br>' : ''}${person.phone1}</td>
              <td>${person.department_name}</td>
              <td>${person.role_name}</td>
              <td>${person.credential_type_name}</td>
            </tr>`;
            } else {
                tableRow = `<tr class="event-person-table-row">
              <th scope="row"><input type="checkbox" class="selectedPersons" data-person-id="${person.id}" data-department-hub-id="${person.department}" data-role-hub-id="${person.role}" data-credential-type-hub-id="${person.credential_type}" name="userSelect"/></th>
              <td>${fullName}</td>
              <td class="event-person-table-email">${person.email1 ? person.email1.split('@').join('@&#8203;') : ''}${person.email1 && person.phone1 ? '<br>' : ''}${person.phone1}</td>
              <td>${person.department_name}</td>
              <td>${person.role_name}</td>
              <td>${person.credential_type_name}</td>
            </tr>`;
            }
            $('#people-results-body').append(tableRow);
            $("#selectAll").click(function () {
                $('.selectedPersons').not(this).prop('checked', this.checked);
            });
            epm.importPeopleButton.show();

        });

    }

    populateTableHeaderForEventsSearch() {
        let table = `<div style="margin-bottom: 10px;"><i>Click Heading To Sort</i></div><table id="importUsersTable" class="standardTable">
            <caption><span class="visuallyHidden"></span></caption>
            <thead>
            <tr>
              <th scope="col"><input type="checkbox" id="selectAll" class="selectAll"  name="selectAll"/>
              </th>
              <th scope="col" class="sortable">Name</th>
              <th scope="col" class="sortable">Prt./Enc.</th>
              <th scope="col" class="sortable">Email &amp; Phone</th>
              <th scope="col" class="sortable">Department</th>
              <th scope="col" class="sortable">Role</th>
              <th scope="col" class="sortable">Credential Type</th>
            </tr>
            </thead>
            <tbody id="people-results-body">

            </tbody>
          </table>
      <a id="personCreateButton" class="button buttonSmall buttonWhite" style="width:200px;margin-top:10px;" href="javascript:void(0)">Not Found? Create New Person</a>`;
        epm.resultsTableWrapper.html(table);
    }

    populateTableHeaderForPeopleSearch() {
        let table = `<div style="margin-bottom: 10px;"><i>Click Heading To Sort</i></div><table id="importUsersTable" class="standardTable">
            <caption><span class="visuallyHidden"></span></caption>
            <thead>
            <tr>
              <th scope="col"><input type="checkbox" id="selectAll" class="selectAll"  name="selectAll"/>
              </th>
              <th scope="col" class="sortable">Name</th>
              <th scope="col" class="sortable">Email &amp; Phone</th>
              <th scope="col" class="sortable">Department</th>
              <th scope="col" class="sortable">Role</th>
              <th scope="col" class="sortable">Credential Type</th>
            </tr>
            </thead>
            <tbody id="people-results-body">

            </tbody>
          </table>
      <a id="personCreateButton" class="button buttonSmall buttonWhite" style="width:200px;margin-top:10px;" href="javascript:void(0)">Not Found? Create New Person</a>`;
        epm.resultsTableWrapper.html(table);
    }

    populateEmptyResult() {
        epm.resultsTableWrapper.html('<div>No results round. Please keep searching or create a new person below if you are sure this person doesn\'t exist.</div><div style="margin-top: 20px;"><a id="personCreateButton" class="button buttonSmall buttonWhite" style="width:200px;" href="javascript:void(0)">Create New Person</a></div>');
    }

}

export let epm = new Epm();
