class PersonEditModal {

  init() {
    this.cropper = null;
    this.person_edit_id = null;
    this.person_edit_hub_id = null;
    this.media_user_edit_id = null;

    this.departments = $('#people_table').data('event-departments');
    this.roles = $('#people_table').data('event-roles');
    this.credential_types = $('#people_table').data('event-cred-types');

    this.personEditModal = $('#person_edit_modal');
    this.personEditModalClose = $('#person_edit_modal_close');
    this.personEditModalPersonName = $('#person_edit_modal_person_name');
    this.personEditModalFormHolder = $('#person_edit_modal_form_holder');
    this.personEditModalBlacklistOptionsBtn = $('#person_edit_modal_blacklist_options_btn');
    this.mediaUserEditModalFormHolder = $('#media_user_edit_modal_form_holder');

    this.personEditModalCredPic = $('#person_edit_modal_cred_pic');
    this.personEditModalCredPicEdit = $('#person_edit_modal_cred_pic_edit');
    this.personEditModalCredPicEditButtonHolder = $('.credPicEditButtonHolder');
    this.personEditModalCredPicEditButtonsHolder = $('.credPicEditButtonsHolder');
    this.personEditModalCredPicCropper = $('.personEditModalCredPicCropper');
    this.personEditModalCredPicSave = $('#cred_pic_save');
    this.personEditModalCredPicCancel = $('#cred_pic_cancel');
    this.personEditModalCredPicSelectorHolder = $('#cred_pic_file_selector_holder');
    this.personEditModalCredPicSelector = $('#cred_pic_file_selector');

    this.personBlacklistOptionsModal = $('#person_blacklist_options_modal');
    this.personBlacklistOptionsModalClose = $('#person_blacklist_options_modal_close');
    this.personBlacklistOptionsModalPersonName = $('#person_blacklist_options_modal_person_name');
    this.personBlacklistOptionsModalBody = $('#person_blacklist_options_modal_body');

    this.personEditModalBlacklistOptionsBtn.on('click', person_edit_modal.personBlacklistOptionsButtonOnClick);
    this.personBlacklistOptionsModalClose.on('click', person_edit_modal.personBlacklistOptionsCloseOnClick);

    $('body').on('click', '.personEditButton', person_edit_modal.personEditButtonOnClick);
    this.personEditModalClose.on('click', person_edit_modal.personEditCloseOnClick);

    this.personEditModalCredPicEdit.on('click', person_edit_modal.personEditCredPicEditClick);
    this.personEditModalCredPicCropper.on('click', person_edit_modal.personEditCredPicCropperClick);
    this.personEditModalCredPicSave.on('click', person_edit_modal.personEditCredPicSaveClick);
    this.personEditModalCredPicCancel.on('click', person_edit_modal.personEditCredPicCancelClick);
    this.personEditModalCredPicSelector.on('change', person_edit_modal.personEditModalCredPicSelectorChange);

    $('body').on('submit', '#person_edit_modal_person_form', person_edit_modal.personEditModalPersonFormOnSubmit);
    $('body').on('submit', '#media_user_edit_modal_person_form', person_edit_modal.personEditModalMediaUserFormOnSubmit);
  };

  personEditButtonOnClick() {
    person_edit_modal.populatePersonEditModal($(this).data('person-id'));
    person_edit_modal.personEditModal.removeClass('modal__hidden');
    person_edit_modal.personEditModal.addClass('modal__visible');
  };

  personEditCloseOnClick() {
    person_edit_modal.personEditModal.removeClass('modal__visible');
    person_edit_modal.personEditModal.addClass('modal__hidden');
    person_edit_modal.destroyCropper();
  };

  personBlacklistOptionsButtonOnClick() {
    person_edit_modal.personBlacklistOptionsModal.removeClass('modal__hidden');
    person_edit_modal.personBlacklistOptionsModal.addClass('modal__visible');
  };

  personBlacklistOptionsCloseOnClick() {
    person_edit_modal.personBlacklistOptionsModal.removeClass('modal__visible');
    person_edit_modal.personBlacklistOptionsModal.addClass('modal__hidden');
  };

  populatePersonEditModal(person_id) {
    let modal_form = '';
    let pdata = $('#person_' + person_id).data('person-detail');

    // Modal Header
    person_edit_modal.personEditModalPersonName.html(pdata.first_name + ' ' + pdata.last_name);
    person_edit_modal.personBlacklistOptionsModalPersonName.html(pdata.first_name + ' ' + pdata.last_name);

    // Cred Pic
    person_edit_modal.personEditModalCredPic.attr('src', null);
    person_edit_modal.personEditModalCredPicSelector.val(null);
    person_edit_modal.destroyCropper();
    person_edit_modal.showEditCredPicButton();
    person_edit_modal.person_edit_id = pdata.id;
    person_edit_modal.person_edit_hub_id = pdata.hub_id;
    person_edit_modal.personEditModalCredPic.attr('src', '/people/' + person_edit_modal.person_edit_id + '/cred_pic?rand=' + Math.random());
    
    // Form
    modal_form += '<form id="person_edit_modal_person_form">';
    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<label>First Name</label><input name="first_name" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.first_name) + '" />';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<label>Middle Name</label><input type="text" name="middle_name" value="' + person_edit_modal.emptyStringIfNull(pdata.middle_name) + '" />';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<label>Last Name</label><input name="last_name" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.last_name) + '" />';
    modal_form += '</div>';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<input name="first_name_override" placeholder="Override" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.first_name_override) + '" />';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<input type="text" name="middle_name_override" placeholder="Override" value="' + person_edit_modal.emptyStringIfNull(pdata.middle_name_override) + '" />';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<input name="last_name_override" placeholder="Override" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.last_name_override) + '" />';
    modal_form += '</div>';
    modal_form += '</div>';
    modal_form += '<label>Email</label><input name="email1" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.email1) + '" />';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<label>Phone (Country Code Required)</label><input name="phone1" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.phone1) + '" />';
    modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr;">';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<label>Department</label><select name="department_ref_id" class="form-control">\n' +
      "<option value></option>\n" +
      person_edit_modal.generateSelectValues(person_edit_modal.departments, pdata.department_ref_id) + '\n</select>';
    modal_form += '<input type="text" name="department_ref_name_override" placeholder="Department Override" value="' + person_edit_modal.emptyStringIfNull(pdata.department_ref_name_override) + '">';
    modal_form += '</div>';
    modal_form += '<div class="sideBySideContainers--container">';
    modal_form += '<label>Role</label><select name="role_ref_id" class="form-control">\n' +
      "<option value></option>\n" +
      person_edit_modal.generateSelectValues(person_edit_modal.roles, pdata.role_ref_id) + '\n</select>';
    modal_form += '<input type="text" name="role_ref_name_override" placeholder="Role Override" value="' + person_edit_modal.emptyStringIfNull(pdata.role_ref_name_override) + '">';
    modal_form += '</div>';
    modal_form += '</div>';
    modal_form += '<label>Credential 1 Type</label><select name="credential_type_ref_id" class="form-control">\n' +
      "<option value></option>\n" +
      person_edit_modal.generateSelectValues(person_edit_modal.credential_types, pdata.credential_type_ref_id) + '\n</select>';
      modal_form += '<label>Credential 2 Type</label><select name="credential2_type_ref_id" class="form-control">\n' +
      "<option value></option>\n" +
      person_edit_modal.generateSelectValues(person_edit_modal.credential_types, pdata.credential2_type_ref_id) + '\n</select>';
    modal_form += '<div class="buttons"><input class="button" type="submit" name="commit" value="Update" /></div>';
    modal_form += '</form>';

    person_edit_modal.personEditModalFormHolder.html(modal_form);

    // Blacklist options modal
    modal_form = '';
    if (pdata.blacklisted == 1) {
      modal_form = '<div>This person has alredy been blacklisted, to undo click <a rel="nofollow" data-method="post" href="/people/' + person_id + '/remove_person_from_blacklist">here</a>.</div><br/>';
    } else {
      modal_form += '<h4>Lost or Misplaced Credential?</h4>';
      modal_form += '<div>If this person has lost or misplaced their credential, click <a rel="nofollow" data-method="post" data-confirm="Are you sure?" href="/people/' + person_id + '/person_lost_credential">here</a>.</div>';
      modal_form += '<br/><div><b>Important:</b> This process will blacklist the old credential and create a new person entity. Please make sure you encode/print a new credential after being redirected.</div>';
      modal_form += '<h4>Should this person be blacklisted forever? (It can be undone)</h4>';
      modal_form += '<div>If this person should no longer have a valid credential, click <a rel="nofollow" data-method="post" href="/people/' + person_id + '/add_person_to_blacklist">here</a>.</div><br/>';
    }
    person_edit_modal.personBlacklistOptionsModalBody.html(modal_form);

    // Media User form
    modal_form = '';
    let mudata = $('#person_' + person_id).data('media-user-detail');

    if (mudata.id) {
      this.media_user_edit_id = mudata.id;
      modal_form += '<form id="media_user_edit_modal_person_form">';
      modal_form += '<h4 style="margin: 0 0 1em 0;">Editor &amp; Organization</h4>';
      modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr;">';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<label>Editor\'s First Name</label><input name="media_user[editor_first_name]" type="text" value="' + person_edit_modal.emptyStringIfNull(mudata.editor_first_name) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<label>Editor\'s Last Name</label><input name="media_user[editor_last_name]" type="text" value="' + person_edit_modal.emptyStringIfNull(mudata.editor_last_name) + '" />';
      modal_form += '</div>';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr;">';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<label>Editor\'s Email</label><input name="media_user[editor_email]" type="text" value="' + person_edit_modal.emptyStringIfNull(mudata.editor_email) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<label>Editor\'s Phone</label><input name="media_user[editor_phone]" type="text" value="' + person_edit_modal.emptyStringIfNull(mudata.editor_phone) + '" />';
      modal_form += '</div>';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;">';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<label>Organization</label><input name="media_user[organization]" type="text" value="' + person_edit_modal.emptyStringIfNull(mudata.organization) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<label>Website</label><input name="media_user[website]" type="text" value="' + person_edit_modal.emptyStringIfNull(mudata.website) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<label>Job Title</label><input name="media_user[job_title]" type="text" value="' + person_edit_modal.emptyStringIfNull(mudata.job_title) + '" />';
      modal_form += '</div>';      
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr;">';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<h4 style="margin: 0 0 1em 0;">Access Status</h4>';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<h4 style="margin: 0 0 1em 0;">Access To All Materials</h4>';
      modal_form += '</div>';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr;">';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<select name="media_user[media_access_status]"><option value="pending" ' + ((mudata.media_access_status == 'pending') ? 'selected' : '') + '>Pending</option><option value="approved" ' + ((mudata.media_access_status == 'approved') ? 'selected' : '') + '>Approved</option><option value="rejected" ' + ((mudata.media_access_status == 'rejected') ? 'selected' : '') + '>Rejected</option></select>';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<select name="media_user[media_all_access]"><option value="0" ' + ((mudata.media_all_access == 0) ? 'selected' : '') + '>No</option><option value="1" ' + ((mudata.media_all_access == 1) ? 'selected' : '') + '>Yes</option></select>';
      modal_form += '</div>';
      modal_form += '</div>';
      modal_form += '<h4 style="margin: 0 0 1em 0;">Dietary Restrictions</h4>';
      modal_form += '<textarea name="media_user[dietary_restrictions]">' + person_edit_modal.emptyStringIfNull(mudata.dietary_restrictions) + '</textarea>';
      modal_form += '<h4 style="margin: 0 0 1em 0;">Internal Notes</h4>';
      modal_form += '<textarea name="media_user[internal_notes]">' + person_edit_modal.emptyStringIfNull(mudata.internal_notes) + '</textarea>';
      modal_form += '<div class="buttons"><input class="button" type="submit" name="commit" value="Update" /></div>';
      modal_form += '</form>';
    }

    person_edit_modal.mediaUserEditModalFormHolder.html(modal_form);
  };

  personEditModalPersonFormOnSubmit(e) {
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: '/people/' + person_edit_modal.person_edit_id + '/ajax_update_person',
      data: $(this).serialize(),
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('Person Updated.');
          setTimeout(function(){ location.reload(); }, 500);
        } else {
          toastr.error(data['message']);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('Person Could Not Be Updated. Please Try Again.');
      }
    });
  };

  personEditModalMediaUserFormOnSubmit(e) {
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: '/media_users/' + person_edit_modal.media_user_edit_id + '/ajax_update_media_user',
      data: $(this).serialize(),
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('Media User Updated.');
          setTimeout(function(){ location.reload(); }, 500);
        } else {
          toastr.error(data['message']);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('Media User Could Not Be Updated. Please Try Again.');
      }
    });
  };

  personEditModalCredPicSelectorChange() {
    if (this.files && this.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        person_edit_modal.personEditModalCredPic.attr('src', e.target.result);
        person_edit_modal.personEditCredPicEditClick();
        person_edit_modal.personEditModalCredPicSave.removeClass('disabled');
      };
      reader.readAsDataURL(this.files[0]);
    }
  };

  personEditCredPicEditClick() {
    person_edit_modal.destroyCropper();
    person_edit_modal.cropper = new Cropper(document.getElementById('person_edit_modal_cred_pic'));
    person_edit_modal.showEditCredPicActionButtons();
  };

  personEditCredPicCropperClick() {
    if (person_edit_modal.cropper == null) { toastr.error('Unable To Edit. Please Refresh And Try Again.'); return; }
    if (this.id == 'cred_pic_rotate') {
      person_edit_modal.cropper.rotate(90);
      person_edit_modal.personEditModalCredPicSave.removeClass('disabled');
    } else {
      if (Object.keys(person_edit_modal.cropper.getCropBoxData()).length != 0) {
        person_edit_modal.personEditModalCredPic.attr('src', person_edit_modal.cropper.getCroppedCanvas({}).toDataURL());
        person_edit_modal.destroyCropper();
        person_edit_modal.cropper = new Cropper(document.getElementById('person_edit_modal_cred_pic'));
        person_edit_modal.personEditModalCredPicSave.removeClass('disabled');
      } else {
        toastr.info('Draw A Box On The Image To Crop.');
      }
    }
  };

  personEditCredPicSaveClick() {
    if ($(this).hasClass('disabled')) { return; }
    if (person_edit_modal.cropper == null) { toastr.error('Unable To Save. Please Refresh And Try Again.'); return; }
    person_edit_modal.personEditModalCredPicSave.addClass('disabled').text('Saving...');
    setTimeout(function(){
      person_edit_modal.cropper.getCroppedCanvas({}).toBlob(function (blob) {
        let form_data = new FormData();
        form_data.append('cred_pic', blob);
        $.ajax('/people/' + person_edit_modal.person_edit_id + '/ajax_save_cred_pic', {
          method: 'POST',
          data: form_data,
          processData: false,
          contentType: false,
          success: function () {
            person_edit_modal.personEditCredPicCancelClick();
            $('#p_' + person_edit_modal.person_edit_id + '_photo_checkmark').html('&check;');
            $('#p_' + person_edit_modal.person_edit_id + '_photo_checkmark').removeClass('red').addClass('green photo-preview');
            toastr.success('Credential Photo Saved.');
          },
          error: function () {
            person_edit_modal.personEditModalCredPicSave.removeClass('disabled').text('Save');
            toastr.error('Unable To Save Credential Photo. Please Try Again.');
          }
        });
      }, 'image/png');
    }, 100);    
  };

  personEditCredPicCancelClick() {
    person_edit_modal.personEditModalCredPic.attr('src', '/people/' + person_edit_modal.person_edit_id + '/cred_pic?rand=' + Math.random());
    person_edit_modal.personEditModalCredPicSelector.val(null);
    person_edit_modal.destroyCropper();
    person_edit_modal.showEditCredPicButton();
  };

  showEditCredPicButton() {
    person_edit_modal.personEditModalCredPicSelectorHolder.removeClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonHolder.removeClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonsHolder.addClass('visuallyHidden');
  };

  showEditCredPicActionButtons() {
    person_edit_modal.personEditModalCredPicSave.addClass('disabled').text('Save');
    person_edit_modal.personEditModalCredPicSelectorHolder.addClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonHolder.addClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonsHolder.removeClass('visuallyHidden');
  };

  destroyCropper() {
    if (person_edit_modal.cropper != null) {
      person_edit_modal.cropper.destroy();
      person_edit_modal.cropper = null;
    }
  };

  emptyStringIfNull(str) {
    if (str === null) { return ''; } else { return str; }
  };

  getValueString(iter, data) {
    if (iter === data) {
      return `value=${iter} selected`;
    } else {
      return `value=${iter}`;
    }
  };

  generateSelectValues(data, personValue) {
    let output = '';
    for (const element of data) {
      output += `<option  ${this.getValueString(element.id, personValue)}>${element.name}</option>\n`;
    }
    return output;
  };

}

export let person_edit_modal = new PersonEditModal();
