class PersonCreateModal {
  init() {
    this.personCreateModal = $('#person_create_modal');
    this.personCreateModalClose = $('#person_create_modal_close');
    this.personCreateModalSubmit = $('#person_create_modal_person_form_submit');

    $('body').on('click', '#personCreateButton', person_create_modal.personCreateButtonOnClick);
    this.personCreateModalClose.on('click', person_create_modal.personCreateCloseOnClick);

    $('body').on('submit', '#person_create_modal_person_form', person_create_modal.personCreateFormOnSubmit);
  };

  personCreateButtonOnClick() {
    person_create_modal.personCreateModal.removeClass('modal__hidden');
    person_create_modal.personCreateModal.addClass('modal__visible');
  };

  personCreateCloseOnClick() {
    person_create_modal.personCreateModal.removeClass('modal__visible');
    person_create_modal.personCreateModal.addClass('modal__hidden');
  };

  personCreateFormOnSubmit(e) {
    e.preventDefault();
    person_create_modal.personCreateModalSubmit.prop('disabled', true);
    $.ajax({
      type: 'POST',
      url: '/people/ajax_create_person',
      data: new FormData(this),
      processData: false,
      contentType: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('Person Created.');
          setTimeout(function () {
            location.reload();
          }, 500);
        } else {
          person_create_modal.personCreateModalSubmit.prop('disabled', false);
          toastr.error(data['message']);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        person_create_modal.personCreateModalSubmit.prop('disabled', false);
        toastr.error('Person Could Not Be Created. Please Try Again.');
      }
    });
  };
}

export let person_create_modal = new PersonCreateModal();