import { materials } from './materials';
import { seating_charts } from './seating_charts';
import { requests } from './requests';
import { credentials } from './credentials';
import { fighters } from './fighters';
import { fighter } from './fighter';
import { media_user_edit_modal } from './media_user_edit_modal';
import { media_user_approval_history_modal } from './media_user_approval_history_modal';
import { files_and_links } from '../misc/files_and_links';

if ($('.media.materials').length) { materials.init(); files_and_links.init();};
if ($('.media.seating_charts').length) { seating_charts.init();};
if ($('.media.requests').length) { requests.init();};
if ($('.media.credentials').length) { credentials.init();};
if ($('.fighters.index').length) { fighters.init();};
if ($('.fighters.show').length) { fighter.init(); files_and_links.init(); };
if ($('.organizations.index').length) { files_and_links.init(); };
if ($('.media.requests').length || $('.media.credentials').length) {
  media_user_edit_modal.init();
  media_user_approval_history_modal.init();
};
