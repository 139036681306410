class HubPushQueueReview {

  init() {
    if (!$('#pending_epm_updates_table').length && !$('#pending_person_updates_table').length) { return; }
    this.currentReviewScreen = $('#pending_epm_updates_table').length ? 'epm' : 'person';

    this.pendingEventPersonTableRow = $('.pendingEventPersonTableRow');
    
    $('#pending_epm_updates_table').on('click', '.acceptPerson', hub_push_queue_review.acceptPersonButtonOnClick);
    $('#pending_epm_updates_table').on('click', '.acceptAllPeople', hub_push_queue_review.acceptAllPeopleButtonOnClick);
    $('#pending_epm_updates_table').on('click', '.rejectPerson', hub_push_queue_review.rejectPersonButtonOnClick);    
    $('#pending_epm_updates_table').on('click', '.rejectAllPeople', hub_push_queue_review.rejectAllPeopleButtonOnClick);
  };

  acceptPersonButtonOnClick() {
    let data = (hub_push_queue_review.currentReviewScreen == 'epm') ? {event_person_id: $(this).data('event-person-id'), pending_queue_ids: $(this).data('pending-queue-ids')} : {person_id: $(this).data('person-id'), pending_queue_ids: $(this).data('pending-queue-ids')};
    $.ajax({
      type: 'POST',
      url: '/hub_push_queue/ajax_accept_person',
      data: data,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) { location.reload(); }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('Could Not Accept Person.');
      }
    });
  };

  acceptAllPeopleButtonOnClick() {
    if (confirm('Are you sure?')) {
      let pending_ids = hub_push_queue_review.collectAllPendingIds();
      let data = (hub_push_queue_review.currentReviewScreen == 'epm') ? {event_person_ids: pending_ids[0], pending_queue_ids: pending_ids[1]} : {person_ids: pending_ids[0], pending_queue_ids: pending_ids[1]};
      $.ajax({
        type: 'POST',
        url: '/hub_push_queue/ajax_accept_all_people',
        data: data,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function (data, textStatus, jqXHR) {
          if (data['success']) { location.reload(); }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          toastr.error('Could Not Accept People.');
        }
      });
    }
  };

  rejectPersonButtonOnClick() {
    if (confirm('Are you sure?')) {
      let data = (hub_push_queue_review.currentReviewScreen == 'epm') ? {event_person_id: $(this).data('event-person-id'), pending_queue_ids: $(this).data('pending-queue-ids')} : {person_id: $(this).data('person-id'), pending_queue_ids: $(this).data('pending-queue-ids')};
      $.ajax({
        type: 'POST',
        url: '/hub_push_queue/ajax_reject_person',
        data: data,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function (data, textStatus, jqXHR) {
          if (data['success']) { location.reload(); }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          toastr.error('Could Not Reject Person.');
        }
      });
    }
  };  

  rejectAllPeopleButtonOnClick() {
    if (confirm('Are you sure?')) {
      let pending_ids = hub_push_queue_review.collectAllPendingIds();
      let data = (hub_push_queue_review.currentReviewScreen == 'epm') ? {event_person_ids: pending_ids[0], pending_queue_ids: pending_ids[1]} : {person_ids: pending_ids[0], pending_queue_ids: pending_ids[1]};
      $.ajax({
        type: 'POST',
        url: '/hub_push_queue/ajax_reject_all_people',
        data: data,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function (data, textStatus, jqXHR) {
          if (data['success']) { location.reload(); }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          toastr.error('Could Not Reject People.');
        }
      });
    }
  };

  collectAllPendingIds() {
    let pending_ids = new Array();
    let pending_queue_ids = new Array();
    hub_push_queue_review.pendingEventPersonTableRow.each(function(){
      pending_ids.push((hub_push_queue_review.currentReviewScreen == 'epm') ? $(this).data('event-person-id') : $(this).data('person-id'));
      pending_queue_ids.push($(this).data('pending-queue-ids'));
    });
    return [pending_ids, pending_queue_ids];
  };

}

export let hub_push_queue_review = new HubPushQueueReview();
