class Requests {
  init(){
    this.credentialRequestId = null;
    this.mediaUserId = null;

    this.accessRequestAcceptOngoing = false;
    this.accessRequestsModal = $('#access_requests_modal');
    this.accessRequestsModalClose = $('#access_requests_modal_close');
    this.accessRequestsModalName = $('#access_requests_modal_name');
    this.accessRequestsModalName = $('#access_requests_modal_name');
    this.accessRequestsModalBtn = $('.accessRequestModalBtn');
    this.accessRequestsModalYesBtnId = 'access_requests_modal_yes_btn';
    this.accessRequestsModalNoBtnId = 'access_requests_modal_no_btn';
    this.accessRequestsNumberHtml = $('#access_requests_number');

    this.accessRequestsNumber = parseInt(this.accessRequestsNumberHtml.text());

    this.credentialRequestAcceptOngoing = false;
    this.credentialRequestsModal = $('#credential_requests_modal');
    this.credentialRequestsModalClose = $('#credential_requests_modal_close');
    this.credentialRequestsModalName = $('#credential_requests_modal_name');
    this.credentialRequestsModalSearch = $('#credential_requests_modal_search');
    this.credentialRequestsModalSearchButton = $('#credential_requests_modal_search_btn');
    this.credentialRequestsModalSearchTable = $('#credential_requests_modal_search_table');
    this.credentialRequestsModalCreateButton = $('#credential_requests_modal_create_btn');
    this.credentialRequestsModalSelectPersonBtnClass = 'credentialRequestsModalSelectPersonBtn';
    this.credentialRequestsModalPrintedCredentialRequiredRb = $('input[type=radio][name=printed_credential_required_rb]');
    this.credentialRequestsModalPrintedCredentialRequiredRbCheckedAccessor = 'input[type=radio][name=printed_credential_required_rb]:checked';
    this.credentialRequestsModalCredentialTypeSection = $('#credential_type_section');
    this.credentialRequestsModalCredentialTypeRb = $('input[type=radio][name=credential_requests_credential_type_rb]');
    this.credentialRequestsModalCredentialTypeRbCheckedAccessor = 'input[type=radio][name=credential_requests_credential_type_rb]:checked';
    this.credentialRequestsModalSeatNumberTi = $('#credential_requests_media_seat_number_ti');
    this.credentialRequestsModalMissingPersonSection = $('#credential_requests_modal_missing_person_section');
    this.credentialRequestsModalAcceptButton = $('#credential_requests_modal_accept_btn');
    this.credentialRequestsNumberHtml = $('#credential_requests_number');
    this.credentialRequestsEventFilter = $('#cr_filter_event_id');

    this.credentialRequestsNumber = parseInt(this.credentialRequestsNumberHtml.text());

    $('body').on('click', '.accessRequestButton', requests.accessRequestsButtonOnClick);
    this.accessRequestsModalClose.on('click', requests.accessRequestsCloseOnClick);
    this.accessRequestsModalBtn.on('click', requests.accessRequestsModalBtnOnClick);

    $('body').on('click', '.credentialRequestButton', requests.credentialRequestsButtonOnClick);
    this.credentialRequestsModalClose.on('click', requests.credentialRequestsCloseOnClick);
    this.credentialRequestsModalSearchButton.on('click', requests.credentialRequestsModalSearchButtonOnClick);
    this.credentialRequestsModalCreateButton.on('click', requests.credentialRequestsModalCreateButtonOnClick);
    this.credentialRequestsModalPrintedCredentialRequiredRb.on('change', requests.credentialRequestsModalPrintedCredentialOnChange);
    this.credentialRequestsModalAcceptButton.on('click', requests.credentialRequestsModalAcceptButtonOnClick);
    this.credentialRequestsEventFilter.on('change', requests.credentialRequestsEventFilterOnChange);
    $('body').on('click', '.' + this.credentialRequestsModalSelectPersonBtnClass, requests.credentialRequestsModalSelectPersonBtnOnClick);
  };

  accessRequestsButtonOnClick() {
    requests.mediaUserId = $(this).data('user-json').id;
    if ($(this).hasClass('accept')) {
      requests.accessRequestAcceptOngoing = false;
      requests.accessRequestsModalName.text($(this).data('user-json').user_full_name);

      // Open modal
      requests.accessRequestsModal.removeClass('modal__hidden');
      requests.accessRequestsModal.addClass('modal__visible');
    } else {
      if (confirm('Are You Sure?')) {
        $.ajax({
          url: '/media/reject_access_request',
          data: { media_user_id: requests.mediaUserId },
          method: 'POST',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          success: function(data){
            if (data['success']){
              $('#access_request_' + requests.mediaUserId).remove();
              requests.decreaseAccessRequestsNumber();
              toastr.success('Successfully Rejected');
            } else {
              toastr.error('Unable To Reject Access Request.');
            };
          },
          error: function(data){
            toastr.error('Unable To Reject Access Request.');
          }
        });
      }
    }
  };

  accessRequestsModalBtnOnClick() {
    if (!requests.accessRequestAcceptOngoing) {
      let media_all_access;
      if (this.id == requests.accessRequestsModalYesBtnId) {
        media_all_access = true;
      } else {
        media_all_access = false;
      }
      toastr.info('Please Wait...');
      requests.accessRequestAcceptOngoing = true;
      $.ajax({
        url: '/media/accept_access_request',
        data: { media_user_id: requests.mediaUserId, media_all_access: media_all_access },
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']){
            requests.accessRequestsCloseOnClick();
            $('#access_request_' + requests.mediaUserId).remove();
            requests.decreaseAccessRequestsNumber();
            toastr.success('Accepted');
            requests.accessRequestAcceptOngoing = false;
          } else {
            toastr.error('Unable To Accept Access Request.');
            requests.accessRequestAcceptOngoing = false;
          };
        },
        error: function(data){
          toastr.error('Unable To Accept Access Request.');
          requests.accessRequestAcceptOngoing = false;
        }
      });
    }
  };

  accessRequestsCloseOnClick() {
    requests.accessRequestsModal.removeClass('modal__visible');
    requests.accessRequestsModal.addClass('modal__hidden');
  };

  credentialRequestsButtonOnClick() {
    requests.credentialRequestId = $(this).data('credential-json').id;
    if ($(this).hasClass('accept')) {
      requests.credentialRequestAcceptOngoing = false;

      // Set modal up
      requests.credentialRequestsModalName.text($(this).data('credential-json').event_name + ' / ' + $(this).data('credential-json').media_user_full_name);
      requests.credentialRequestsModalCredentialTypeRb.first().prop('checked', true);
      requests.credentialRequestsModalSeatNumberTi.val('');
      requests.credentialRequestsModalCreateButton.addClass('visuallyHidden');

      if ($(this).data('credential-json').media_user_person_id == null) {
        requests.credentialRequestsModalSearch.val('');
        requests.credentialRequestsModalSearchButton.text('Search');        
        requests.credentialRequestsModalSearchTable.html('');
        requests.credentialRequestsModalAcceptButton.addClass('visuallyHidden');
        requests.credentialRequestsModalMissingPersonSection.removeClass('visuallyHidden');
      } else {
        requests.credentialRequestsModalAcceptButton.removeClass('visuallyHidden');
        requests.credentialRequestsModalMissingPersonSection.addClass('visuallyHidden');
      }

      // Open modal
      requests.credentialRequestsModal.removeClass('modal__hidden');
      requests.credentialRequestsModal.addClass('modal__visible');
    } else {
      if (confirm('Are You Sure?')) {
        $.ajax({
          url: '/media/reject_credential_request',
          data: { credential_request_id: requests.credentialRequestId },
          method: 'POST',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          success: function(data){
            if (data['success']){
              $('#credential_request_' + requests.credentialRequestId).remove();
              requests.decreaseCredentialRequestsNumber();
              toastr.success('Successfully Rejected');
            } else {
              toastr.error('Unable To Reject Credential Request.');
            };
          },
          error: function(data){
            toastr.error('Unable To Reject Credential Request.');
          }
        });
      }
    }
  };

  credentialRequestsModalAcceptButtonOnClick() {
    requests.triggerAcceptCredentialRequestAjaxCall($(requests.credentialRequestsModalPrintedCredentialRequiredRbCheckedAccessor).val(), $(requests.credentialRequestsModalCredentialTypeRbCheckedAccessor).val(), requests.credentialRequestsModalSeatNumberTi.val(), requests.credentialRequestId, null, false);
  };

  credentialRequestsModalPrintedCredentialOnChange() {
    if($('#printed_credential_yes_rb').is(':checked')) {
      requests.credentialRequestsModalCredentialTypeSection.show();
    } else {
      requests.credentialRequestsModalCredentialTypeSection.hide();
    }
  };

  credentialRequestsModalSearchButtonOnClick() {
    if (!requests.credentialRequestsModalSearch.val()) {
      toastr.error('Please Provide A Search Term.');
    } else {
      let $this = $(this);
      $this.text('Searching...');
      $.ajax({
        url: '/people/ajax_search',
        data: { filter: requests.credentialRequestsModalSearch.val() },
        method: 'GET',
        success: function(data){
          $this.text('Search');
          if (data['success']){
            let rows = '';
            data['people']['Data'].forEach(function(person){
              rows += '<tr>';
              rows += '<td scope="row">' + (person.FirstName ? person.FirstName : '') + (person.MiddleName ? (' ' + person.MiddleName) : '') + ' ' + (person.LastName ? person.LastName : '') + '</td>';
              rows += '<td>' + (person.Email1 ? person.Email1 : '') + '</td>';
              rows += '<td>' + (person.Phone1 ? person.Phone1 : '')+ '</td>';
              rows += '<td><a data-person-hub-id="' + person.Id + '" class="button buttonSmall ' + requests.credentialRequestsModalSelectPersonBtnClass + '" href="javascript:void(0)">Select</a></td>';
              rows += '</tr>';
            });
            requests.credentialRequestsModalSearchTable.html(rows);
            requests.credentialRequestsModalCreateButton.removeClass('visuallyHidden');
          } else {
            toastr.error('Unable To Search.');
          };
        },
        error: function(data){
          $this.text('Search');
          toastr.error('Unable To Search.');
        }
      });
    }
  };

  credentialRequestsModalCreateButtonOnClick() {
    requests.triggerAcceptCredentialRequestAjaxCall($(requests.credentialRequestsModalPrintedCredentialRequiredRbCheckedAccessor).val(), $(requests.credentialRequestsModalCredentialTypeRbCheckedAccessor).val(), requests.credentialRequestsModalSeatNumberTi.val(), requests.credentialRequestId, null, true);
  };

  credentialRequestsModalSelectPersonBtnOnClick() {
    requests.triggerAcceptCredentialRequestAjaxCall($(requests.credentialRequestsModalPrintedCredentialRequiredRbCheckedAccessor).val(), $(requests.credentialRequestsModalCredentialTypeRbCheckedAccessor).val(), requests.credentialRequestsModalSeatNumberTi.val(), requests.credentialRequestId, $(this).data('person-hub-id'), true);
  };

  triggerAcceptCredentialRequestAjaxCall(printed_credential_required, credential_type_ref_id, seat_number, credential_request_id, person_hub_id, reload) {
    if (!requests.credentialRequestAcceptOngoing) {
      toastr.info('Please Wait...');
      requests.credentialRequestAcceptOngoing = true;
      $.ajax({
        url: '/media/accept_credential_request',
        data: { printed_credential_required: printed_credential_required, credential_type_ref_id: credential_type_ref_id, seat_number: seat_number, credential_request_id: credential_request_id, person_hub_id: person_hub_id },
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']){
            if (reload) {
              location.reload();
            } else {
              requests.credentialRequestsCloseOnClick();
              $('#credential_request_' + credential_request_id).remove();
              requests.decreaseCredentialRequestsNumber();
              toastr.success('Accepted');
              requests.credentialRequestAcceptOngoing = false;
            }
          } else {
            toastr.error('Unable To Accept Credential Request.');
            requests.credentialRequestAcceptOngoing = false;
          };
        },
        error: function(data){
          toastr.error('Unable To Accept Credential Request.');
          requests.credentialRequestAcceptOngoing = false;
        }
      });
    }
  };

  credentialRequestsEventFilterOnChange() {
    window.location = '/media/requests?event_id=' + $(this).val();
  };

  credentialRequestsCloseOnClick() {
    requests.credentialRequestsModal.removeClass('modal__visible');
    requests.credentialRequestsModal.addClass('modal__hidden');
  };

  decreaseAccessRequestsNumber() {
    requests.accessRequestsNumber -= 1;
    requests.accessRequestsNumberHtml.text(requests.accessRequestsNumber);
  };

  decreaseCredentialRequestsNumber() {
    requests.credentialRequestsNumber -= 1;
    requests.credentialRequestsNumberHtml.text(requests.credentialRequestsNumber);
  };

}
export let requests = new Requests();