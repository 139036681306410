class FilesAndLinks {
  init(){
    this.downloadButton = $('.fileDownloadLink');
    this.followLinkButton = $('.fileFollowLink');
    this.downloadButton.on('click', files_and_links.download);
    this.followLinkButton.on('click', files_and_links.followLink);

    this.materialsUploadModal = $('#materials_upload_modal');
    this.materialsUploadModalClose = $('#materials_upload_modal_close');
    this.materialsUploadModalName = $('#materials_upload_modal_name');
    this.materialsUploadModalFormsHolder = $('#materials_upload_modal_forms_holder');
    this.materialsUploadModalUploadForm = $('#materials_upload_modal_upload_form');
    this.materialsUploadModalUploadFormHolder = $('#materials_upload_modal_upload_form_holder');
    this.materialsUploadModalLinkForm = $('#materials_upload_modal_link_form');
    this.materialsUploadModalLinkFormHolder = $('#materials_upload_modal_link_form_holder');
    this.materialsUploadModalFighterNote = $('#materials_upload_fighter_note');
    this.materialsUploadModalOrganizationNote = $('#materials_upload_organization_note');

    $('body').on('click', '.materialsUploadButton', files_and_links.materialsUploadButtonOnClick);
    this.materialsUploadModalClose.on('click', files_and_links.materialsUploadCloseOnClick);    
  }

  download(e){
    if ($(e.target).hasClass('removeItem')) {
      return;
    }
    var filePath = $(this).data('file-path');
    $.ajax({
      url: '/media/download_file',
      data: {file_path: filePath},
      method: 'GET',
      success: function(data){
        //open file download in new tab
        if (data['success'] == true){
          window.open(data['download_url'], '_blank');
        } else {
          toastr.error('Unable To Download File.');
        };
      },
      error: function(data){
        toastr.error('Unable To Download File.');
      }
    });
  };

  followLink(e){
    if ($(e.target).hasClass('removeItem')) {
      return;
    }
    window.open($(this).data('url'), '_blank');
  };

  materialsUploadButtonOnClick() {
    // Clear fields
    files_and_links.materialsUploadModalUploadForm.find("input[name='files[]']").val('');
    files_and_links.materialsUploadModalLinkForm.find("input[name='display_text']").val('');
    files_and_links.materialsUploadModalLinkForm.find("input[name='url']").val('');
    // Populate control fields
    let modal_name;
    if ($(this).data('materials-category') == 'event') {
      files_and_links.materialsUploadModalUploadForm.attr('action', '/media/upload_file');
      files_and_links.materialsUploadModalUploadForm.find("input[name='event_id']").val($(this).data('event-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='event_hub_id']").val($(this).data('event-hub-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='file_category']").val($(this).data('materials-subcategory'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='file_category']").val($(this).data('materials-subcategory'));

      files_and_links.materialsUploadModalLinkForm.attr('action', '/media/create_event_link');
      files_and_links.materialsUploadModalLinkForm.find("input[name='event_id']").val($(this).data('event-id'));
      files_and_links.materialsUploadModalLinkForm.find("input[name='category']").val($(this).data('materials-subcategory'));

      files_and_links.materialsUploadModalFighterNote.addClass('visuallyHidden');
      files_and_links.materialsUploadModalOrganizationNote.addClass('visuallyHidden');

      if ($(this).data('materials-subcategory') == 'logos') {
        modal_name = 'Event Logos';
        files_and_links.materialsUploadModalFormsHolder.addClass('sideBySideContainers');
        files_and_links.materialsUploadModalLinkFormHolder.css('display', '');
      } else if ($(this).data('materials-subcategory') == 'docs') {
        modal_name = 'Event Files';
        files_and_links.materialsUploadModalFormsHolder.addClass('sideBySideContainers');
        files_and_links.materialsUploadModalLinkFormHolder.css('display', '');
      } else {
        modal_name = 'Event Banner';
        files_and_links.materialsUploadModalFormsHolder.removeClass('sideBySideContainers');
        files_and_links.materialsUploadModalLinkFormHolder.css('display', 'none');
      }
    } else if ($(this).data('materials-category') == 'fight') {
      files_and_links.materialsUploadModalUploadForm.attr('action', '/media/upload_file');
      files_and_links.materialsUploadModalUploadForm.find("input[name='event_id']").val($(this).data('event-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='event_hub_id']").val($(this).data('event-hub-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='fight_id']").val($(this).data('fight-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='fight_hub_id']").val($(this).data('fight-hub-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='file_category']").val('fights/' + $(this).data('fight-hub-id'));

      files_and_links.materialsUploadModalLinkForm.attr('action', '/media/create_fight_link');
      files_and_links.materialsUploadModalLinkForm.find("input[name='event_id']").val($(this).data('event-id'));
      files_and_links.materialsUploadModalLinkForm.find("input[name='fight_id']").val($(this).data('fight-id'));

      files_and_links.materialsUploadModalFighterNote.addClass('visuallyHidden');
      files_and_links.materialsUploadModalOrganizationNote.addClass('visuallyHidden');

      modal_name = $('#fight_' + $(this).data('fight-id') + '_name').text();
    } else if ($(this).data('materials-category') == 'fighter') {
      let fighterId = $(this).data('fighter-id');
      files_and_links.materialsUploadModalUploadForm.attr('action', `/media/fighters/${fighterId}/upload_file`);
      files_and_links.materialsUploadModalUploadForm.find("input[name='event_id']").val($(this).data('event-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='fight_id']").val($(this).data('fight-id'));
      files_and_links.materialsUploadModalUploadForm.find("input[name='fighter_id']").val(fighterId);
      files_and_links.materialsUploadModalUploadForm.find("input[name='fighter_hub_id']").val($(this).data('fighter-hub-id'));

      files_and_links.materialsUploadModalLinkForm.attr('action', `/media/fighters/${fighterId}/create_link`);
      files_and_links.materialsUploadModalLinkForm.find("input[name='event_id']").val($(this).data('event-id'));
      files_and_links.materialsUploadModalLinkForm.find("input[name='fight_id']").val($(this).data('fight-id'));
      files_and_links.materialsUploadModalLinkForm.find("input[name='fighter_id']").val(fighterId);
      
      if ($('.media.materials').length) {
        files_and_links.materialsUploadModalFighterNote.removeClass('visuallyHidden');
      }
      files_and_links.materialsUploadModalOrganizationNote.addClass('visuallyHidden');

      modal_name = $(this).data('fighter-name');
    } else if ($(this).data('materials-category') == 'organization') {
      let organizationId = $(this).data('organization-id');
      files_and_links.materialsUploadModalUploadForm.attr('action', `/media/organizations/${organizationId}/upload_file`);
      files_and_links.materialsUploadModalUploadForm.find("input[name='organization_id']").val(organizationId);
      files_and_links.materialsUploadModalUploadForm.find("input[name='organization_hub_id']").val($(this).data('organization-hub-id'));

      files_and_links.materialsUploadModalLinkForm.attr('action', `/media/organizations/${organizationId}/create_link`);
      files_and_links.materialsUploadModalLinkForm.find("input[name='organization_id']").val(organizationId);
      files_and_links.materialsUploadModalLinkForm.find("input[name='organization_hub_id']").val($(this).data('organization-hub-id'));

      files_and_links.materialsUploadModalFighterNote.addClass('visuallyHidden');
      files_and_links.materialsUploadModalOrganizationNote.removeClass('visuallyHidden');

      modal_name = 'Promotion Materials';
    }

    files_and_links.materialsUploadModalName.text(modal_name);

    // Open modal
    files_and_links.materialsUploadModal.removeClass('modal__hidden');
    files_and_links.materialsUploadModal.addClass('modal__visible');
  };

  materialsUploadCloseOnClick() {
    files_and_links.materialsUploadModal.removeClass('modal__visible');
    files_and_links.materialsUploadModal.addClass('modal__hidden');
  };

}
export let files_and_links = new FilesAndLinks();