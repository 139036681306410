class Materials {
  init(){
    this.fightResultModal = $('#fight_result_modal');
    this.fightResultModalClose = $('#fight_result_modal_close');
    this.fightResultModalName = $('#fight_result_modal_name');
    this.fightResultModalForm = $('#fight_result_modal_form');

    $('body').on('click', '.fightResultButton', materials.fightResultButtonOnClick);
    this.fightResultModalClose.on('click', materials.fightResultCloseOnClick);
  };

  fightResultButtonOnClick() {
    // Populate control fields
    materials.fightResultModalForm.find("input[name='event_id']").val($(this).data('event-id'));
    materials.fightResultModalForm.find("input[name='fight_id']").val($(this).data('fight-id'));
    // Populate or clear field
    if ($('#fight_' + $(this).data('fight-id') + '_result').text() != 'Pending') {
      materials.fightResultModalForm.find("input[name='result']").val($('#fight_' + $(this).data('fight-id') + '_result').text());
    } else {
      materials.fightResultModalForm.find("input[name='result']").val('');
    }
    materials.fightResultModalName.text($('#fight_' + $(this).data('fight-id') + '_name').text());

    // Open modal
    materials.fightResultModal.removeClass('modal__hidden');
    materials.fightResultModal.addClass('modal__visible');
  };

  fightResultCloseOnClick() {
    materials.fightResultModal.removeClass('modal__visible');
    materials.fightResultModal.addClass('modal__hidden');
  };

}
export let materials = new Materials();