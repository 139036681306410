import { person_create_modal } from './person_create_modal';
import { person_edit_modal } from './person_edit_modal';
import { media_user_approval_history_modal } from '../media/media_user_approval_history_modal';
import { person_delete } from './person_delete';

if ($('.people').length) { 
  person_create_modal.init();
  person_edit_modal.init();
  person_delete.init();
  media_user_approval_history_modal.init();
};