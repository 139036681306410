class Encoder {

  init() {
    $('body').on('click', '.encodeCard', this.onEncodeCardClick);
  }

  onEncodeCardClick() {
    let event_person_id = $(this).data('event-person-id');
    let person_id = $(this).data('person-id');
    let credential_type_ref_id = $(this).data('event-person-credential-type-ref-id');
    let credential_num = $(this).data('credential-num');
    
    if (credential_type_ref_id) {
      $.ajax({
        url: 'http://localhost:8321/writeCard',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {
          name: $(this).data('event-person-last-name').last_name,
          userId: person_id,
          eventId: $(this).data('event-terminal-code'),
          accessType: credential_type_ref_id
        },
        success: function(data) {
          if (data['status'] == 'success') {
            toastr.success(data['message']);

            // Update encoded timestamp
            $.ajax({
              url: event_person_id ? ('/event_people/' + event_person_id + '/ajax_update_credential_encoded_ts') : ('/people/' + person_id + '/ajax_update_credential_encoded_ts'),
              data: {credential_num: credential_num},
              method: 'POST',
              beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
              },
              success: function (data) {
                if (data['success']) {
                  if (event_person_id) {
                    $(`#ep_${event_person_id}_encoded_checkmark${credential_num ? credential_num : ''}`).html('&check;');
                    $(`#ep_${event_person_id}_encoded_checkmark${credential_num ? credential_num : ''}`).removeClass('red').addClass('green');
                  } else {
                    $(`#p_${person_id}_encoded_checkmark${credential_num ? credential_num : ''}`).html('&check;');
                    $(`#p_${person_id}_encoded_checkmark${credential_num ? credential_num : ''}`).removeClass('red').addClass('green');
                  }
                } else {
                  toastr.error('Unable To Mark Row As Encoded.');
                }
                ;
              },
              error: function (data) {
                toastr.error('Unable To Mark Row As Encoded.');
              }
            });
          } else {
            toastr.error(data['message']);
          }
        },
        error: function(data) {
          toastr.error('Card Not Written.');
        }
      });
    } else {
      toastr.error('Missing Credential Type. Please assign one before encoding.');
    }
  };

}

export let encoder = new Encoder();